export const comments = {
  ja: {
    score: {
      up: `生活習慣スコアは12月より%d点上がりました。寒さに負けず、良い生活習慣を身につけられていますね。<br>
      2月1日からWalk 個人チャレンジ 2022 3rdが開催！<br>
      歩く時は３つのポイントを意識しましょう。<br>
      <b>①姿勢</b><br>
      <b>②大股歩き</b><br>
      <b>③早歩き</b>`,
      down: `生活習慣スコアは12月より%d点下がりました。分類別評価で、どの項目に変化があったか見てみましょう。<br>
      2月1日からWalk 個人チャレンジ 2022 3rdが開催！<br>
      歩く時は３つのポイントを意識しましょう。<br>
      <b>①姿勢</b><br>
      <b>②大股歩き</b><br>
      <b>③早歩き</b>`,
      equal100: `生活習慣スコアは12月に引き続き%満点%です。良い習慣が継続できていて大変素晴らしいです！<br>
      2月1日からWalk 個人チャレンジ 2022 3rdが開催！<br>
      歩く時は３つのポイントを意識しましょう。<br>
      <b>①姿勢</b><br>
      <b>②大股歩き</b><br>
      <b>③早歩き</b>`,
      equal: `生活習慣スコアは12月と同じ点数です。分類別評価で、どの項目に変化があったか見てみましょう。<br>
      2月1日からWalk 個人チャレンジ 2022 3rdが開催！<br>
      歩く時は３つのポイントを意識しましょう。<br>
      <b>①姿勢</b><br>
      <b>②大股歩き</b><br>
      <b>③早歩き</b>`,
    },
    steps: {
      up: `歩数スコアは12月より%d点上がりました。<br>
      10分歩くと約1000歩と言われています。日々の歩数を振り返りながら、この調子で歩いていきましょう。`,
      down: `歩数スコアは12月より%d点下がりました。<br>
      10分歩くと約1000歩と言われています。10分まとめて、もしくは5分×２回歩くだけで、今より1000歩歩数が増やせますよ。`,
      equal: `歩数スコアは12月と同じ点数です。<br>
      10分歩くと約1000歩と言われています。10分まとめて、もしくは5分×２回歩くだけで、今より1000歩歩数が増やせますよ。`,
    },
    exercise: {
      up: `運動のスコアは12月より%d点上がりました。<br>
      ウォーキングやサイクリングなどの有酸素運動をして、幸せホルモンと呼ばれる「セロトニン」を増やそう。
      `,
      down: `運動のスコアは12月より%d点下がりました。<br>
      ウォーキングやサイクリングなどの有酸素運動をして、幸せホルモンと呼ばれる「セロトニン」を増やそう。
      `,
      equal: `運動のスコアは12月と同じ点数です。<br>
      ウォーキングやサイクリングなどの有酸素運動をして、幸せホルモンと呼ばれる「セロトニン」を増やそう。
      `,
    },
    meal: {
      up: `食事のスコアは12月より%d点上がりました。<br>
      食事の時、一口30回以上噛むことが理想です。あなたは何回噛んでいますか？少ない人は、まずは今より5回増やしてみましょう。
      `,
      down: `食事のスコアは12月より%d点下がりました。<br>
      食事の時、一口30回以上噛むことが理想です。あなたは何回噛んでいますか？少ない人は、まずは今より5回増やしてみましょう。
      `,
      equal: `食事のスコアは12月と同じ点数です。<br>
      食事の時、一口30回以上噛むことが理想です。あなたは何回噛んでいますか？少ない人は、まずは今より5回増やしてみましょう。
      `,
    },
    drinking: {
      up: `飲酒のスコアは12月より%d点上がりました。<br>
      アルコールには利尿作用があり、お酒を飲むと脱水症状になりやすいです。
      こまめに水を飲むことを意識しましょう。
      `,
      down: `飲酒のスコアは12月より%d点下がりました。<br>
      アルコールには利尿作用があり、お酒を飲むと脱水症状になりやすいです。
      こまめに水を飲むことを意識しましょう。
      `,
      equal: `飲酒のスコアは12月と同じ点数です。<br>
      アルコールには利尿作用があり、お酒を飲むと脱水症状になりやすいです。
      こまめに水を飲むことを意識しましょう。
      `,
    },
    sleep: {
      up: `睡眠のスコアは12月より%d点上がりました。<br>
      質の良い睡眠をとると、仕事中の作業能力や集中力UPに繋がります。引き続き、この調子で！
      `,
      down: `睡眠のスコアは12月より%d点下がりました。<br>
      日中の眠気対策には、脳や体を覚醒させてくれるストレッチがおすすめ！体をひねったり、伸びをしてみましょう。
      `,
      equal: `睡眠のスコアは12月と同じ点数です。<br>
      日中の眠気対策には、脳や体を覚醒させてくれるストレッチがおすすめ！体をひねったり、伸びをしてみましょう。
      `,
    },
    stress: {
      up: `ストレスのスコアは12月より%d点上がりました。<br>
      ”笑う”ことで、心身ともにリラックス効果が期待できます。口角を上げて、声を出して笑いましょう！
      `,
      down: `ストレスのスコアは12月より%d点下がりました。<br>
      ”笑う”ことで、心身ともにリラックス効果が期待できます。口角を上げて、声を出して笑いましょう！
      `,
      equal: `ストレスのスコアは12月と同じ点数です。<br>
      ”笑う”ことで、心身ともにリラックス効果が期待できます。口角を上げて、声を出して笑いましょう！
      `,
    },
  },
  en: {
    score: {
      up: `Your lifestyle score has gone up by %d pts since December. You're maintaining good lifestyle habits, without letting the cold get the better of you.<br>
      The Walk Individual Challenge 2022 3rd Round will be held from February 1！<br>
      Keep these three things in mind when walking: <br>
      (1) Good posture <br>
      (2) Taking big steps <br>
      (3) Walking fast<br>
      `,
      down: `Your lifestyle score has gone down %d pts since December. Use the categorized ratings to see what items have changed.<br>
      The Walk Individual Challenge 2022 3rd Round will be held from February 1！<br>
      Keep these three things in mind when walking: <br>
      (1) Good posture <br>
      (2) Taking big steps <br>
      (3) Walking fast<br>
      `,
      equal100: `Your lifestyle score is the %maximum% again, the same as December. You're keeping up a great lifestyle! Wonderful job!<br>
      The Walk Individual Challenge 2022 3rd Round will be held from February 1！<br>
      Keep these three things in mind when walking: <br>
      (1) Good posture <br>
      (2) Taking big steps <br>
      (3) Walking fast<br>
      `,
      equal: `Your lifestyle score is the same as December. Use the categorized ratings to review your progress item by item.<br>
      The Walk Individual Challenge 2022 3rd Round will be held from February 1！<br>
      Keep these three things in mind when walking: <br>
      (1) Good posture <br>
      (2) Taking big steps <br>
      (3) Walking fast<br>
      `,
    },
    steps: {
      up: `Your steps score has gone up %d pts since December.<br>
      It's said that a 10-minute walk is about 1,000 steps.Review your daily steps and keep up the pace.
      `,
      down: `Your steps score has gone down %d pts since December. <br>
      It's said that a 10-minute walk is about 1,000 steps. Just do a 10-minute walk, or two 5-minute walks, and you'll get 1,000 more steps.
      `,
      equal: `Your steps score is the same as December. <br>
      It's said that a 10-minute walk is about 1,000 steps. Just do a 10-minute walk, or two 5-minute walks, and you'll get 1,000 more steps.
      `,
    },
    exercise: {
      up: `Your exercise score has gone up %d pts since December.<br>
      Do cardio like walking or cycling to increase your serotonin, also known as the happiness hormone.
      `,
      down: `Your exercise score has gone down %d pts since December.<br>
      Do cardio like walking or cycling to increase your serotonin, also known as the happiness hormone.
      `,
      equal: `Your exercise score is the same as December.<br>
      Do cardio like walking or cycling to increase your serotonin, also known as the happiness hormone.
      `,
    },
    meal: {
      up: `Your food score has gone up %d pts since December.<br>
      Ideally, you should chew at least 30 times per bite when you eat. How many times do you chew? If you don't chew enough, start by chewing 5 more times per bite.
      `,
      down: `Your food score has gone down %d pts since December.<br>
      Ideally, you should chew at least 30 times per bite when you eat. How many times do you chew? If you don't chew enough, start by chewing 5 more times per bite.
      `,
      equal: `Your food score is the same as December.<br>
      Ideally, you should chew at least 30 times per bite when you eat. How many times do you chew? If you don't chew enough, start by chewing 5 more times per bite.
      `,
    },
    drinking: {
      up: `Your alcohol score has gone up %d pts since December.<br>
      Alcohol has a diuretic affect, so drinking it leaves you prone to dehydration. 
      Make sure you drink water frequently.`,
      down: `Your alcohol score has gone down %d pts since December.<br>
      Alcohol has a diuretic affect, so drinking it leaves you prone to dehydration. 
      Make sure you drink water frequently.`,
      equal: `Your alcohol score is the same as December.<br>
      Alcohol has a diuretic affect, so drinking it leaves you prone to dehydration. 
      Make sure you drink water frequently.`,
    },
    sleep: {
      up: `Your sleep score has gone up %d pts since December.<br>
      Getting a good night's sleep can improve your abilities and concentration at work. Keep it up!
      `,
      down: `Your sleep score has gone down %d pts since December.<br>
      For drowsiness during the day, wake up your mind and body by stretching! Twist and stretch your body.
      `,
      equal: `Your sleep score is the same as December.<br>
      For drowsiness during the day, wake up your mind and body by stretching! Twist and stretch your body.
      `,
    },
    stress: {
      up: `Your stress score has gone up %d pts since December.<br>
      Laughing can help relax both the body and mind.  Open your mouth, raise your voice, and laugh!
      `,
      down: `Your stress score has gone down %d pts since December.<br>
      Laughing can help relax both the body and mind.  Open your mouth, raise your voice, and laugh!
      `,
      equal: `Your stress score is the same as December.<br>
      Laughing can help relax both the body and mind.  Open your mouth, raise your voice, and laugh!
      `,
    },
  },
}
